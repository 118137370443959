import { BaseListFilterType, initBaseListFilterType } from "@/shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} MdfeListFilterExType
 * @property {boolean|null} status
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & MdfeListFilterExType)} MdfeListFilterType
 */
export const MdfeListFilterType = {};

/**
 * Inicializar MdfeListFilterType
 *
 * @returns {MdfeListFilterType}
 */
export const initMdfeListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    company_plant_issuer_id: null,
    number_mdfe: {
      min: null,
      max: null,
    },
    number_nfe: {
      min: null,
      max: null,
    },
    range: {
      start: null,
      end: null,
    },
    status: null,
    company_plant_id: null,
  };
}
